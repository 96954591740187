import { Fragment } from 'react';

import { Button, SelectItem, Select, InputField } from '@sb/ui/components';
import { TrashIcon } from '@sb/ui/icons';

import type {
  ModbusTCPRegisterData,
  ModbusTCPServerRegisterFormat,
  ModbusTCPServerRegisterKind,
} from '../types/types';
import { convertRegisterTypeToDisplayName } from '../util';

import styles from './ModbusTCPServerEquipmentManager.module.css';

const registerDataFormatOptions: Array<{
  value: ModbusTCPServerRegisterFormat;
}> = [
  { value: 'UInt16BE' },
  { value: 'Int16BE' },
  { value: 'UInt16LE' },
  { value: 'Int16LE' },
  { value: 'UInt32BE' },
  { value: 'Int32BE' },
  { value: 'UInt32LE' },
  { value: 'Int32LE' },
  { value: 'UInt64BE' },
  { value: 'Int64BE' },
  { value: 'UInt64LE' },
  { value: 'Int64LE' },
];

const registerKindOptions: Array<{
  name: string;
  value: ModbusTCPServerRegisterKind;
}> = [
  { name: 'Status Coil', value: 'statusCoil' },
  { name: 'Input Coil', value: 'inputCoil' },
  { name: 'Holding Register', value: 'holdingRegister' },
  { name: 'Input Register', value: 'inputRegister' },
];

interface ModbusTCPServerRegisterFieldListProps {
  registerFieldList: Array<ModbusTCPRegisterData>;
  isFormDisabled: boolean;
  removeRegisterFieldRow: (id: string) => void;
  onChange: (value: ModbusTCPRegisterData) => void;
}

export function ModbusTCPServerRegisterFieldList({
  registerFieldList,
  isFormDisabled,
  removeRegisterFieldRow,
  onChange,
}: ModbusTCPServerRegisterFieldListProps) {
  return (
    <>
      {registerFieldList.map((register) => {
        return (
          <Fragment key={register.id}>
            {/* register name */}
            <InputField
              inputContainerClassName={styles.field}
              data-testid="input-register-name"
              value={register.name}
              onChange={(e) =>
                onChange({
                  ...register,
                  name: e.target.value,
                })
              }
            />

            {/* register type */}
            <Select
              className={styles.field}
              data-testid="select-register-type"
              activeLabel={convertRegisterTypeToDisplayName(register.type)}
              isDisabled={isFormDisabled}
            >
              {registerKindOptions.map((kind) => {
                return (
                  <SelectItem
                    key={kind.value}
                    value={kind.value}
                    onClick={() =>
                      onChange({
                        ...register,
                        type: kind.value,
                      })
                    }
                  >
                    {kind.name}
                  </SelectItem>
                );
              })}
            </Select>

            {/* Byte offset */}
            <InputField
              inputContainerClassName={styles.field}
              data-testid="input-register-offset"
              value={register.offset}
              onChange={(e) =>
                onChange({
                  ...register,
                  offset: e.target.value,
                })
              }
            />

            {/* data format */}
            <Select
              className={styles.field}
              data-testid="select-register-data-format"
              activeLabel={register.format}
              isDisabled={isFormDisabled}
            >
              {registerDataFormatOptions.map((format) => {
                return (
                  <SelectItem
                    key={format.value}
                    value={format.value}
                    onClick={() =>
                      onChange({
                        ...register,
                        format: format.value,
                      })
                    }
                  >
                    {format.value}
                  </SelectItem>
                );
              })}
            </Select>

            <Button
              startIcon={<TrashIcon />}
              variant="alertSecondary"
              onClick={() => {
                removeRegisterFieldRow(register.id);
              }}
            />
          </Fragment>
        );
      })}
    </>
  );
}
