import type { Integrations } from '@sb/types';
import { VerticalLiftIcon } from '@sb/ui/icons';

import { getDynamicBaseDefaults } from '../frontend/getDynamicBaseDefaults';

import type { EwellixLiftTLTConfiguration } from './types/Configuration';
import { EwellixLiftTLTWidget } from './widget/EwellixLiftTLTWidget';

export const ewellixLiftTLTImplementation: Integrations.IntegrationFrontend = {
  ...getDynamicBaseDefaults(),
  InlineIcon: VerticalLiftIcon,
  getSupportedSteps: () => [],
  getDisplayName: () => {
    return 'Ewellix Lift TLT';
  },
  getManufacturerName: () => {
    return 'Ewellix';
  },
  getShortName: () => {
    return 'Lift TLT';
  },
  getIntegrationVariables: () => [],
  getDefaultItem: (): EwellixLiftTLTConfiguration => {
    return {
      kind: 'EwellixLiftTLT',
    };
  },
  Widget: EwellixLiftTLTWidget,
  getSummaryStatus: (state) => {
    if (state.kind !== 'EwellixLiftTLT') {
      return undefined;
    }

    return state.isMoving ? 'busy' : 'idle';
  },
};
