import type { Integrations } from '@sb/types';
import { EthernetIcon } from '@sb/ui/icons';

import {
  MODBUS_TCP_SERVER_NAME_DEFAULT,
  ONROBOT_TCP_PORT_DEFAULT,
  TCP_CONNECTION_TIMEOUT_MS_DEFAULT,
} from '../constants';
import { ModbusTCPServerEquipmentManager } from '../equipment-manager/ModbusTCPServerEquipmentManager';
import type { ModbusTCPServerConfiguration } from '../types/Configuration';

export const modbusTCPServerImplementation: Integrations.IntegrationFrontend<ModbusTCPServerConfiguration> =
  {
    InlineIcon: EthernetIcon,
    isHidden: () => true,
    getIntegrationType: () => 'Other',
    getSupportedSteps: () => [],
    getDisplayName: () => {
      return MODBUS_TCP_SERVER_NAME_DEFAULT;
    },
    getManufacturerName: () => {
      return MODBUS_TCP_SERVER_NAME_DEFAULT;
    },
    getShortName: () => {
      return MODBUS_TCP_SERVER_NAME_DEFAULT;
    },
    getIntegrationVariables: () => [],
    getDefaultItem: (): ModbusTCPServerConfiguration => {
      return {
        kind: 'ModbusTCPServer',
        connectionOptions: {
          host: '0.0.0.0',
          port: ONROBOT_TCP_PORT_DEFAULT,
          timeoutMS: TCP_CONNECTION_TIMEOUT_MS_DEFAULT,
        },
      };
    },
    EquipmentManagerEditForm: ModbusTCPServerEquipmentManager,
  };
