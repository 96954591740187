import cx from 'classnames';

import type { Integrations } from '@sb/types';
import {
  Layout,
  InputLabel,
  Select,
  SelectItem,
  Typography,
} from '@sb/ui/components';
import { InwardMountingPosition } from '@sb/ui/icons/InwardMountingPosition';
import { OutwardMountingPosition } from '@sb/ui/icons/OutwardMountingPosition';
import { alignItems, display, margin } from '@sb/ui/styles';
import { NoEquipmentEditorForm } from '@sbrc/components/equipment-manager/NoEquipmentEditorForm';
import { useFeatureFlag } from '@sbrc/hooks';

import type { OR2FG7FingerPosition } from '../constants';
import { OR_2FG7_FINGER_POSITION_DEFAULT } from '../constants';

import styles from './OnRobot2FG7EquipmentManager.module.css';

export function OnRobot2FG7EquipmentManager({
  item,
  onUpdate,
  isFormDisabled,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'OnRobot2FG7') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  const isConfigEnabled = useFeatureFlag('config2FG7');

  const { fingerConfiguration } = item;

  function getFingerMountingPositionDisplayName(
    fingerMount: OR2FG7FingerPosition,
  ) {
    const text = (
      <Typography variant="medium">
        {fingerMount === 'inward' ? 'Inward' : 'Outward'}
      </Typography>
    );

    const icon =
      fingerMount === 'inward' ? (
        <InwardMountingPosition
          size="small"
          className={margin.right.extraSmall}
        />
      ) : (
        <OutwardMountingPosition
          size="small"
          className={margin.right.extraSmall}
        />
      );

    return (
      <div className={cx(display.flex.row, alignItems.center)}>
        {icon}
        {text}
      </div>
    );
  }

  if (!isConfigEnabled) {
    return <NoEquipmentEditorForm />;
  }

  return (
    <Layout.Flex direction="vertical">
      <Typography component="h5" className={margin.bottom.extraSmall}>
        Finger geometry
      </Typography>
      <Layout.Flex direction="vertical">
        <Layout.FlexChild grow={1}>
          <Layout.Flex direction="vertical">
            <div className={styles.inputWidth}>
              <Select
                label={<InputLabel>Mounting position</InputLabel>}
                activeLabel={getFingerMountingPositionDisplayName(
                  fingerConfiguration?.mountingPosition ??
                    OR_2FG7_FINGER_POSITION_DEFAULT,
                )}
                isDisabled={isFormDisabled}
              >
                <SelectItem
                  value="inward"
                  onClick={() =>
                    onUpdate({
                      ...item,
                      fingerConfiguration: {
                        ...fingerConfiguration,
                        mountingPosition: 'inward',
                      },
                    })
                  }
                >
                  {getFingerMountingPositionDisplayName('inward')}
                </SelectItem>

                <SelectItem
                  value="outward"
                  onClick={() =>
                    onUpdate({
                      ...item,
                      fingerConfiguration: {
                        ...fingerConfiguration,
                        mountingPosition: 'outward',
                      },
                    })
                  }
                >
                  {getFingerMountingPositionDisplayName('outward')}
                </SelectItem>
              </Select>
            </div>
          </Layout.Flex>
        </Layout.FlexChild>
      </Layout.Flex>
    </Layout.Flex>
  );
}
