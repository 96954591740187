import cx from 'classnames';
import { useEffect, useMemo } from 'react';

import { SAFEGUARD_KIND_NAMES } from '@sb/routine-runner';
import type { Robot } from '@sb/types';
import { InputField } from '@sb/ui/components';
import { useRobotIOState } from '@sbrc/hooks';

import IOBadge from './IOBadge';
import IOCard from './IOCard';
import IOSendSignalButton from './IOSendSignalButton';

import styles from './IOEditor.module.css';

const EMPTY_IO_NAME_ERROR_MESSAGE = 'I/O ports cannot have an empty name';
const DUPLICATED_IO_NAME_ERROR_MESSAGE = 'I/O ports cannot have the same name';
const EMPTY_SIGNAL_NAME_ERROR_MESSAGE = 'Signal names cannot be empty';

const DUPLICATE_SIGNAL_NAME_ERROR_MESSAGE =
  'Low and high signals cannot be equal';

interface IOEditorProps {
  io: Robot.IOPort;
  ioInputs: Robot.IOPort[];
  ioOutputs: Robot.IOPort[];
  kind: Robot.IOPortKind;
  onIOChange: (io: Robot.IOPort) => void;
  onValidationChange: (port: number, isValid: boolean) => void;
  robotID: string;
}

const IOEditor = ({
  kind,
  io,
  ioInputs,
  ioOutputs,
  onIOChange,
  onValidationChange,
  robotID,
}: IOEditorProps) => {
  const ioState = useRobotIOState({ kind, port: io.port, robotID });

  const validHighSignalNameErrorMessage = useMemo(() => {
    if (!io.highSignalName.trim().length) {
      return EMPTY_SIGNAL_NAME_ERROR_MESSAGE;
    }

    return io.highSignalName.toLowerCase().trim() ===
      io.lowSignalName.toLowerCase().trim()
      ? DUPLICATE_SIGNAL_NAME_ERROR_MESSAGE
      : null;
  }, [io.highSignalName, io.lowSignalName]);

  const validLowSignalNameErrorMessage = useMemo(() => {
    if (!io.lowSignalName.trim().length) {
      return EMPTY_SIGNAL_NAME_ERROR_MESSAGE;
    }

    return io.highSignalName.toLowerCase().trim() ===
      io.lowSignalName.toLowerCase().trim()
      ? DUPLICATE_SIGNAL_NAME_ERROR_MESSAGE
      : null;
  }, [io.highSignalName, io.lowSignalName]);

  const validIONameErrorMessage = useMemo(() => {
    const isEmptyIOName = !io.name.trim().length;

    const isDuplicatedInputName =
      io.deviceName === undefined &&
      ioInputs.some((ioInput) => {
        const isExistingInputNameMatch =
          io.name.toLowerCase().trim() === ioInput.name.toLowerCase().trim();

        return kind === 'Input'
          ? io.port !== ioInput.port && isExistingInputNameMatch
          : isExistingInputNameMatch;
      });

    const isDuplicatedOutputName = ioOutputs.some((ioOutput) => {
      const isExistingOutputNameMatch =
        io.name.toLowerCase().trim() === ioOutput.name.toLowerCase().trim();

      return kind === 'Output'
        ? io.port !== ioOutput.port && isExistingOutputNameMatch
        : isExistingOutputNameMatch;
    });

    if (isEmptyIOName) {
      return EMPTY_IO_NAME_ERROR_MESSAGE;
    }

    if (isDuplicatedInputName || isDuplicatedOutputName) {
      return DUPLICATED_IO_NAME_ERROR_MESSAGE;
    }

    return null;
  }, [io.name, io.port, io.deviceName, ioInputs, ioOutputs, kind]);

  const isValidIOConfiguration =
    !validHighSignalNameErrorMessage &&
    !validLowSignalNameErrorMessage &&
    !validIONameErrorMessage;

  // Update validation
  useEffect(() => {
    onValidationChange(io.port, isValidIOConfiguration);
  }, [io.port, isValidIOConfiguration, onValidationChange]);

  const subtitle = useMemo(() => {
    if (io.safeguardKind) {
      return `Assigned to safety function: ${
        SAFEGUARD_KIND_NAMES[io.safeguardKind]
      }`;
    }

    if (io.deviceName) {
      return `Assigned to device: ${io.deviceName}`;
    }

    return undefined;
  }, [io.safeguardKind, io.deviceName]);

  return (
    <IOCard
      title={io.deviceName ?? io.name}
      subtitle={subtitle}
      containerClassName={styles.ioEditor}
    >
      <div className={styles.ioContainer}>
        <IOBadge className={styles.inputBadge}>
          {kind === 'Input' ? 'IN' : 'OUT'} {io.port}
        </IOBadge>

        <InputField
          value={io.name}
          hasError={!!validIONameErrorMessage}
          helperText={validIONameErrorMessage ?? undefined}
          sizeVariant="small"
          onChange={(event) => {
            onIOChange({ ...io, name: event.target.value });
          }}
          onClear={() => onIOChange({ ...io, name: `${kind} ${io.port}` })}
          className={styles.ioNameInputFieldContainer}
          inputClassName={cx(styles.ioNameInputFieldInput, {
            [styles.inputNameInputField]: kind === 'Input',
            [styles.outputNameInputField]: kind === 'Output',
          })}
          disabled={io.deviceName !== undefined}
        />
      </div>

      <div className={styles.ioContainer}>
        <IOBadge
          className={styles.inputBadge}
          isCurrentIOLevel={ioState === 'high'}
        >
          High
        </IOBadge>

        {kind === 'Output' && (
          <IOSendSignalButton
            className={styles.sendSignalButton}
            ioPort={io.port}
            ioLevel="high"
            robotID={robotID}
            disabled={ioState === 'high'}
            kind={kind}
          />
        )}

        <InputField
          value={io.highSignalName}
          hasError={!!validHighSignalNameErrorMessage}
          helperText={validHighSignalNameErrorMessage ?? undefined}
          sizeVariant="small"
          onChange={(event) => {
            onIOChange({ ...io, highSignalName: event.target.value });
          }}
          onClear={() => onIOChange({ ...io, highSignalName: 'High' })}
          inputContainerClassName={cx(styles.levelIOFieldInput, {
            [styles.levelIOOutputFieldInput]: kind === 'Output',
          })}
          disabled={
            io.safeguardKind !== undefined || io.deviceName !== undefined
          }
        />
      </div>

      <div className={styles.ioContainer}>
        <IOBadge
          className={styles.inputBadge}
          isCurrentIOLevel={ioState === 'low'}
        >
          Low
        </IOBadge>

        {kind === 'Output' && (
          <IOSendSignalButton
            className={styles.sendSignalButton}
            ioPort={io.port}
            ioLevel="low"
            robotID={robotID}
            disabled={ioState === 'low'}
            kind={kind}
          />
        )}

        <InputField
          value={io.lowSignalName}
          hasError={!!validLowSignalNameErrorMessage}
          helperText={validLowSignalNameErrorMessage ?? undefined}
          sizeVariant="small"
          onChange={(event) => {
            onIOChange({ ...io, lowSignalName: event.target.value });
          }}
          onClear={() => onIOChange({ ...io, lowSignalName: 'Low' })}
          inputContainerClassName={cx(styles.levelIOFieldInput, {
            [styles.levelIOOutputFieldInput]: kind === 'Output',
          })}
          disabled={
            io.safeguardKind !== undefined || io.deviceName !== undefined
          }
        />
      </div>
    </IOCard>
  );
};

export default IOEditor;
