import { pick } from 'lodash';
import { get as getValueFromObject, set as setValueInObject } from 'lodash/fp';
import { createContext, useContext, useEffect, useState } from 'react';
import type { StoreApi } from 'zustand';
import { createStore, useStore } from 'zustand';
import { shallow } from 'zustand/shallow';

import type { SafetySettingsPreset } from '@sb/feathers-types';
import { COLLABORATIVE_MAX_TOOLTIP_SPEED } from '@sb/motion-planning';
import type { Robot, SafetySettings } from '@sb/types';
import { useToast } from '@sbrc/hooks';
import { getSafetySettings, updateSafetySettings } from '@sbrc/services';

type LockState = 'loading' | 'locked' | 'enterPIN' | 'unlocked';

interface Store {
  robotID: string | null;
  ioInputs: Robot.InputPort[];
  settings: SafetySettings.ConvertedResponse | null;
  invalidFields: string[];
  lockState: LockState;
  setLockState: (lockState: LockState) => void;
  hasUnsavedChanges: boolean;
}

const StoreContext = createContext<StoreApi<Store>>(null!);

function useStoreApi() {
  return useContext(StoreContext);
}

interface StoreProviderProps {
  ioInputs: Robot.InputPort[];
  children?: React.ReactNode;
}

function useCreateStore({ ioInputs }: StoreProviderProps): StoreApi<Store> {
  const [store] = useState(() =>
    createStore<Store>((set) => ({
      robotID: null,
      ioInputs,
      settings: null,
      invalidFields: [],
      lockState: 'loading',
      setLockState: (lockState) => set({ lockState }),
      hasUnsavedChanges: false,
    })),
  );

  return store;
}

export function StoreProvider({ children, ...rest }: StoreProviderProps) {
  const contextValue = useCreateStore(rest);

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
}

/**
 * Use a Settings value in the Settings form
 * @param fieldName Property path of the value in SafetySettings.ConvertedResponse (passed in to lodash get/set)
 * @param convertValue Convert the raw value in store for use in the component
 * @returns Converted value; setter; onValidationChange handler; isDisabled flag
 */
export function useSettingValue<V>(
  fieldName: string,
  convertValue: (value: any) => V,
): readonly [
  value: V,
  setValue: (newValue: V) => void,
  onValidationChange: (isValid: boolean) => void,
  isDisabled: boolean,
] {
  const storeApi = useStoreApi();

  const { value, disabled } = useStore(
    storeApi,
    (state) => {
      return {
        value: convertValue(getValueFromObject(fieldName, state.settings)),
        disabled: state.lockState !== 'unlocked',
      };
    },
    shallow,
  );

  const setValue = (newValue: V) => {
    storeApi.setState((state) => {
      if (state.settings) {
        return {
          settings: setValueInObject(fieldName, newValue, state.settings),
          hasUnsavedChanges: true,
        };
      }

      return { settings: null, hasUnsavedChanges: false };
    });
  };

  const onValidationChange = (isValid: boolean) => {
    storeApi.setState((state) => {
      if (state.invalidFields.includes(fieldName)) {
        if (isValid) {
          return {
            invalidFields: state.invalidFields.filter((f) => f !== fieldName),
          };
        }
      } else if (!isValid) {
        return { invalidFields: [...state.invalidFields, fieldName] };
      }

      return { invalidFields: state.invalidFields };
    });
  };

  return [value, setValue, onValidationChange, disabled] as const;
}

/** initialize store from db */
export function useReadSettings(robotID: string): void {
  const { setState } = useStoreApi();
  const { setToast } = useToast();

  useEffect(() => {
    let cancelled = false;

    (async () => {
      try {
        const savedSettings = await getSafetySettings(robotID);

        if (!cancelled) {
          setState({ robotID, settings: savedSettings, lockState: 'locked' });
        }
      } catch (e) {
        setToast({ kind: 'error', message: 'Settings read failed' });
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [setState, robotID, setToast]);
}

/** return function to update db */
export function useUpdateSettings(): () => Promise<void> {
  const { getState, setState } = useStoreApi();
  const { setToast } = useToast();

  return async () => {
    const { robotID, settings } = getState();

    if (robotID && settings) {
      try {
        await updateSafetySettings(robotID, settings);
        setState({ hasUnsavedChanges: false });
      } catch (e) {
        setToast({ kind: 'error', message: 'Settings update failed' });
      }
    }
  };
}

export function useFormError(): string | null {
  const storeApi = useStoreApi();

  return useStore(storeApi, (state) => {
    if (state.invalidFields.length > 0) {
      return 'Some values are invalid, check field errors above';
    }

    if (
      state.settings?.maxTooltipSpeed &&
      state.settings.maxTooltipSpeed > COLLABORATIVE_MAX_TOOLTIP_SPEED &&
      state.settings.safeguardRules.every((r) => r.kind === 'none')
    ) {
      return 'The speed limits entered could endanger humans nearby. To apply them you must also configure a Safety I/O device to trigger a stop or reduced speed operation.';
    }

    return null;
  });
}

export function useFactoryPreset(): [
  factoryPreset: SafetySettingsPreset,
  setSettings: (newSettings: Partial<SafetySettings.ConvertedResponse>) => void,
  isDisabled: boolean,
] {
  const storeApi = useStoreApi();

  const { factoryPreset, isDisabled } = useStore(
    storeApi,
    (state) => {
      return {
        factoryPreset: state.settings?.factoryPreset ?? 'none',
        isDisabled: state.lockState !== 'unlocked',
      } as const;
    },
    shallow,
  );

  return [
    factoryPreset,
    (newSettings) => {
      storeApi.setState((state) => ({
        settings: state.settings && {
          ...state.settings,
          ...newSettings,
        },
        hasUnsavedChanges: true,
      }));
    },
    isDisabled,
  ];
}

export function useSafetySettingsStore<
  P extends keyof (Store & SafetySettings.ConvertedResponse),
>(...props: P[]): Pick<Store & Partial<SafetySettings.ConvertedResponse>, P> {
  const storeApi = useStoreApi();

  return useStore(
    storeApi,
    (state) => pick({ ...state, ...state.settings }, props),
    shallow,
  );
}
