import type { Integrations, RoutineVariable } from '@sb/types';
import { VacuumIcon } from '@sb/ui/icons';

import { getGripperImplementationDefaults } from '../frontend/getGripperImplementationDefaults';

import {
  COMPUTEBOX_HOST_DEFAULT,
  ORVGP20_DEFAULT_TOOLTIP_POSE,
} from './constants';
import { OnRobotVGP20EquipmentManager } from './equipment-manager/OnRobotVGP20EquipmentManager';
import type { OnRobotVGP20Configuration } from './types/Configuration';
import { OnRobotVGP20Widget } from './widget/OnRobotVGP20Widget';

export const INTEGRATION_VARIABLES: RoutineVariable.GripperStateVariableInformation[] =
  [
    // TODO
  ];

export const onRobotVGP20Implementation: Integrations.IntegrationFrontend<OnRobotVGP20Configuration> =
  {
    ...getGripperImplementationDefaults(),
    InlineIcon: VacuumIcon,
    Widget: OnRobotVGP20Widget,
    getIntegrationVariables: () => INTEGRATION_VARIABLES,
    getSupportedSteps: () => ['ActuateVacuum'],
    getDisplayName: () => {
      return 'OnRobot VGP20';
    },
    getManufacturerName: () => {
      return 'OnRobot';
    },
    getShortName: () => {
      return 'VGP20';
    },
    getDefaultItem: (): OnRobotVGP20Configuration => {
      return {
        kind: 'OnRobotVGP20',
        connectionOptions: {
          host: COMPUTEBOX_HOST_DEFAULT,
          port: 502,
        },
        tooltipConfig: {
          tooltipPose: ORVGP20_DEFAULT_TOOLTIP_POSE,
        },
      };
    },
    EquipmentManagerEditForm: OnRobotVGP20EquipmentManager,
    getSummaryStatus: (state) => {
      if (state.kind !== 'OnRobotVGP20') {
        return undefined;
      }

      if (!state.isConnected) {
        return 'disconnected';
      }

      if (state.error) {
        return { kind: 'error', message: state.error };
      }

      return state.isBusy ? 'busy' : 'idle';
    },
  };
