import type { Integrations } from '@sb/types';
import { CustomIOIcon } from '@sb/ui/icons';

import { CustomIOEquipmentManager } from './equipment-manager/CustomIOEquipmentManager';
import type { CustomIOConfiguration } from './types/Configuration';

export const customIOImplementation: Integrations.IntegrationFrontend<CustomIOConfiguration> =
  {
    InlineIcon: CustomIOIcon,
    getIntegrationType: () => 'Other',
    getSupportedSteps: () => [],
    getDisplayName: () => {
      return 'Custom IO Device';
    },
    getManufacturerName: () => {
      return 'Custom IO';
    },
    getShortName: () => {
      return 'IO Device';
    },
    getIntegrationVariables: () => [],
    getDefaultItem: (): CustomIOConfiguration => {
      return {
        kind: 'CustomIO',
        ports: [],
      };
    },
    EquipmentManagerEditForm: CustomIOEquipmentManager,
  };
