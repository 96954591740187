import base62Random from 'base62-random';
import cx from 'classnames';
import { useCallback, useMemo } from 'react';

import type { Integrations } from '@sb/types';
import {
  Layout,
  Typography,
  InputField,
  Button,
  NumberInput,
} from '@sb/ui/components';
import { AddIcon } from '@sb/ui/icons';
import { alignItems, display, justifyContent, margin } from '@sb/ui/styles';

import { ONROBOT_TCP_PORT_DEFAULT } from '../constants';
import type { ModbusTCPRegisterData } from '../types/types';

import { ModbusTCPServerRegisterFieldList } from './ModbusTCPServerRegisterFieldList';

import styles from './ModbusTCPServerEquipmentManager.module.css';

export function ModbusTCPServerEquipmentManager({
  item,
  isFormDisabled,
  onUpdate,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'ModbusTCPServer') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  const registerList: Array<ModbusTCPRegisterData> = useMemo(
    () => item.registers ?? [],
    [item.registers],
  );

  const addRegisterFieldRow = () => {
    const registerId = base62Random(6);

    const defaultRegisterData: ModbusTCPRegisterData = {
      id: registerId,
      name: '',
      type: 'holdingRegister',
      offset: '3x1000',
      format: 'UInt16BE',
    };

    let registerFieldList: Array<ModbusTCPRegisterData> = [];

    if (item.registers) {
      registerFieldList = [...item.registers];
    }

    registerFieldList.push(defaultRegisterData);

    onUpdate({ ...item, registers: registerFieldList });
  };

  const removeRegisterFieldRow = (registerId: string) => {
    const registerDataList = [...registerList];

    const currentIndex = registerDataList.findIndex(
      (register) => register.id === registerId,
    );

    registerDataList.splice(currentIndex, 1);

    onUpdate({
      ...item,
      registers: registerDataList,
    });
  };

  const onRegisterFieldChange = useCallback(
    (registerValue: ModbusTCPRegisterData) => {
      const regList = [...registerList];

      const currentIndex = regList.findIndex(
        (reg) => reg.id === registerValue.id,
      );

      regList[currentIndex] = { ...regList[currentIndex], ...registerValue };

      onUpdate({
        ...item,
        registers: regList,
      });
    },
    [item, onUpdate, registerList],
  );

  return (
    <>
      <Layout.Flex
        direction="vertical"
        className={cx(margin.bottom.medium, margin.top.medium)}
      >
        <div className={cx(styles.connectionGrid, margin.bottom.medium)}>
          <InputField
            placeholder="IP address"
            value={item.connectionOptions.host}
            onChange={(e) =>
              onUpdate({
                ...item,
                connectionOptions: {
                  ...item.connectionOptions,
                  host: e.target.value,
                },
              })
            }
          >
            IP Address (Host)
          </InputField>

          <InputField
            placeholder="port"
            value={item.connectionOptions.port ?? ONROBOT_TCP_PORT_DEFAULT}
            onChange={(e) =>
              onUpdate({
                ...item,
                connectionOptions: {
                  ...item.connectionOptions,
                  port: Number(e.target.value),
                },
              })
            }
          >
            Port
          </InputField>

          {/* connection timeout displayed in seconds */}
          <NumberInput
            value={item.connectionOptions.timeoutMS / 1_000}
            onChange={(value) =>
              onUpdate({
                ...item,
                connectionOptions: {
                  ...item.connectionOptions,
                  timeoutMS: value * 1_000,
                },
              })
            }
            step={1}
            suffix="sec"
          >
            Timeout
          </NumberInput>
        </div>
      </Layout.Flex>

      <div
        className={cx(
          display.flex.row,
          justifyContent.spaceBetween,
          margin.bottom.small,
        )}
      >
        <div className={cx(display.flex.row, alignItems.center)}>
          <Typography className={margin.right.extraSmall} component="h5">
            Register Fields
          </Typography>
          <Typography color="gray" component="h6">
            ({item.registers?.length ?? 0})
          </Typography>
        </div>

        <Button
          onClick={() => addRegisterFieldRow()}
          startIcon={<AddIcon />}
          variant="link"
        >
          Add Field
        </Button>
      </div>

      <div className={cx(styles.grid)}>
        <Typography
          data-testid="input-label-register-name"
          className={styles.cell}
        >
          Name
        </Typography>

        <Typography
          data-testid="select-label-register-type"
          className={styles.cell}
        >
          Type
        </Typography>

        <Typography
          data-testid="input-label-register-offset"
          className={styles.cell}
        >
          Offset
        </Typography>

        <Typography
          data-testid="select-label-register-data-format"
          className={styles.cell}
        >
          Format
        </Typography>
        <div className={styles.cell}>&nbsp;</div>

        <ModbusTCPServerRegisterFieldList
          registerFieldList={registerList}
          removeRegisterFieldRow={removeRegisterFieldRow}
          onChange={onRegisterFieldChange}
          isFormDisabled={isFormDisabled}
        />
      </div>
    </>
  );
}
