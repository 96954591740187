import type { Integrations, RoutineVariable } from '@sb/types';
import { TwoFingerGripperIcon } from '@sb/ui/icons';

import { getGripperImplementationDefaults } from '../frontend/getGripperImplementationDefaults';

import {
  OR_2FG7_FINGER_POSITION_DEFAULT,
  OR2FG7_DEFAULT_TOOLTIP_POSE,
} from './constants';
import { OnRobot2FG7EquipmentManager } from './equipment-manager/OnRobot2FG7EquipmentManager';
import type { OnRobot2FG7GrippersConfiguration } from './types/Configuration';
import { OnRobot2FG7Widget } from './widget/OnRobot2FG7Widget';

export const INTEGRATION_VARIABLES: RoutineVariable.GripperStateVariableInformation[] =
  [
    {
      kind: 'gripperState',
      selector: 'gripperState.width.inner',
      variableKind: 'number',
      displayName: 'Inner width',
      variableName: 'kinematicState.gripperState.width.inner',
      userIdentifier: 'Gripper_width_inner',
      unitLabel: 'M',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.width.outer',
      variableKind: 'number',
      displayName: 'Outer width',
      variableName: 'kinematicState.gripperState.width.outer',
      userIdentifier: 'Gripper_width_outer',
      unitLabel: 'M',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.forceAppliedNewtons',
      variableKind: 'number',
      displayName: 'Gripped with force',
      variableName: 'kinematicState.gripperState.forceAppliedNewtons',
      userIdentifier: 'Gripper_forceAppliedNewtons',
      unitLabel: 'N',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.isGripDetected',
      variableKind: 'boolean',
      displayName: 'Gripped',
      values: [{ displayName: 'Gripped', value: true }],
      variableName: 'kinematicState.gripperState.isGripDetected',
      userIdentifier: 'Gripper_isGripDetected',
    },
    {
      kind: 'gripperState',
      selector: 'gripperState.isForceGripDetected',
      variableKind: 'boolean',
      displayName: 'Gripped with force specified in previous step',
      values: [{ displayName: 'Gripped', value: true }],
      variableName: 'kinematicState.gripperState.isForceGripDetected',
      userIdentifier: 'Gripper_isForceGripDetected',
    },
  ];

export const onRobot2FG7Implementation: Integrations.IntegrationFrontend<OnRobot2FG7GrippersConfiguration> =
  {
    ...getGripperImplementationDefaults(),
    InlineIcon: TwoFingerGripperIcon,
    getSupportedSteps: () => ['ActuateGripper'],
    getDisplayName: () => {
      return 'OnRobot 2FG7';
    },
    getManufacturerName: () => {
      return 'OnRobot';
    },
    getShortName: () => {
      return '2FG7';
    },
    getIntegrationVariables: () => INTEGRATION_VARIABLES,
    getDefaultItem: (): OnRobot2FG7GrippersConfiguration => {
      return {
        kind: 'OnRobot2FG7',
        fingerConfiguration: {
          mountingPosition: OR_2FG7_FINGER_POSITION_DEFAULT,
        },
        tooltipConfig: {
          tooltipPose: OR2FG7_DEFAULT_TOOLTIP_POSE,
        },
      };
    },
    Widget: OnRobot2FG7Widget,
    EquipmentManagerEditForm: OnRobot2FG7EquipmentManager,
    getSummaryStatus: (state) => {
      if (state.kind !== 'OnRobot2FG7') {
        return undefined;
      }

      if (!state.isConnected) {
        return 'disconnected';
      }

      if (state.uncalibratedError) {
        return { kind: 'error', message: 'Uncalibrated' };
      }

      if (state.linearSensorError) {
        return { kind: 'error', message: 'Linear sensor error' };
      }

      return state.isBusy ? 'busy' : 'idle';
    },
  };
