import cx from 'classnames';
import { useState } from 'react';

import type { Integrations } from '@sb/types';
import {
  Typography,
  Card,
  Button,
  Dropdown,
  MenuItem,
} from '@sb/ui/components';
import { Flex, FlexChild } from '@sb/ui/components/Layout';
import { ForwardIcon, SwitchIcon } from '@sb/ui/icons';
import { margin, padding } from '@sb/ui/styles';

import type { DualGripperCompatibleGrippers } from '../types/Configuration';

import styles from './shared.module.css';

interface QuickChangerItemProps {
  item: DualGripperCompatibleGrippers;
  onClick: () => void;
  onChange: (item: DualGripperCompatibleGrippers) => void;
  isDisabled: boolean;
  getEquipmentByKind: Integrations.GetEquipmentByKind;
}

export function QuickChangerItem({
  item,
  onClick,
  onChange,
  isDisabled,
  getEquipmentByKind,
}: QuickChangerItemProps) {
  const otherOptions = (['NoGripper', 'OnRobot2FG7', 'OnRobot3FG15'] as const)
    .filter((kind) => kind !== item.kind)
    .map((kind) => {
      const frontend = getEquipmentByKind(kind);

      return {
        label: frontend.getDisplayName(),
        defaultItem: {
          ...(frontend.getDefaultItem() as DualGripperCompatibleGrippers),
          name: frontend.getDisplayName(),
        },
        kind,
      };
    });

  const equipmentFrontendImplementation = getEquipmentByKind(item.kind);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Flex direction="horizontal" className={margin.top.small}>
      <FlexChild className={styles.container}>
        {item.kind === 'NoGripper' ? (
          <Card borderSize="medium" hasBorder>
            <Flex
              direction="horizontal"
              center
              className={cx(padding.small, styles.noGripper)}
            >
              <FlexChild>
                <Flex direction="vertical">
                  <Typography variant="large">
                    {equipmentFrontendImplementation.getDisplayName()}
                  </Typography>
                </Flex>
              </FlexChild>
            </Flex>
          </Card>
        ) : (
          <Card borderSize="medium" hasBorder onClick={() => onClick()}>
            <Flex
              direction="horizontal"
              center
              className={cx(padding.small, styles.gripper)}
            >
              <equipmentFrontendImplementation.InlineIcon size="medium" />
              <FlexChild>
                <div className={margin.left.small}>
                  <Flex direction="vertical">
                    <Typography variant="large">
                      {item.name ??
                        equipmentFrontendImplementation.getDisplayName()}
                    </Typography>
                  </Flex>
                </div>
              </FlexChild>
              <ForwardIcon size="small" />
            </Flex>
          </Card>
        )}
      </FlexChild>
      <FlexChild>
        <Dropdown
          isOpen={isOpen}
          className={cx(styles.fullHeightButton)}
          onClose={() => setIsOpen(false)}
          content={otherOptions.map(({ defaultItem, label }) => (
            <MenuItem
              key={label}
              onClick={() => onChange(defaultItem)}
              hasDivider
            >
              {label}
            </MenuItem>
          ))}
        >
          <Button
            size="medium"
            variant="secondary"
            startIcon={<SwitchIcon />}
            className={cx(styles.fullHeightButton, margin.left.small)}
            onClick={() => setIsOpen(true)}
            disabled={isDisabled}
          >
            Swap
          </Button>
        </Dropdown>
      </FlexChild>
    </Flex>
  );
}
