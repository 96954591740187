import cx from 'classnames';

import type { Integrations } from '@sb/types';
import { Typography } from '@sb/ui/components';
import { margin } from '@sb/ui/styles';

import { AddPortButton } from './AddPortButton';
import { CustomIOEquipmentManagerContextProvider } from './CustomIOEquipmentManagerContext';
import { IOPortTable } from './IOPortTable';

import styles from './CustomIOEquipmentManager.module.css';

export function CustomIOEquipmentManager({
  item,
  onUpdate,
  robot,
  otherEquipment,
}: Integrations.EquipmentManagerEditFormProps) {
  if (item.kind !== 'CustomIO') {
    throw new Error(`Invalid item kind: ${item.kind}`);
  }

  const inputs = item.ports.filter((port) => port.kind === 'Input');
  const outputs = item.ports.filter((port) => port.kind === 'Output');

  return (
    <div>
      <CustomIOEquipmentManagerContextProvider
        portKind="Input"
        assignedPorts={item.ports}
        robotIOInputs={robot.ioInputs}
        robotIOOutputs={robot.ioOutputs}
        otherEquipment={otherEquipment}
      >
        <div>
          <Typography component="h5" className={margin.bottom.extraSmall}>
            Inputs
          </Typography>
          <div>
            <IOPortTable
              ports={inputs}
              onChange={(updatedPorts) =>
                onUpdate({
                  ...item,
                  ports: [...updatedPorts, ...outputs],
                })
              }
            />
          </div>
          <div className={cx(margin.top.small, styles.buttonContainer)}>
            <AddPortButton
              label="Add input port"
              onAdd={(addedPort) =>
                onUpdate({
                  ...item,
                  ports: [...inputs, addedPort, ...outputs],
                })
              }
            />
          </div>
        </div>
      </CustomIOEquipmentManagerContextProvider>

      <CustomIOEquipmentManagerContextProvider
        portKind="Output"
        assignedPorts={item.ports}
        robotIOInputs={robot.ioInputs}
        robotIOOutputs={robot.ioOutputs}
        otherEquipment={otherEquipment}
      >
        <div className={margin.top.medium}>
          <Typography component="h5" className={margin.bottom.extraSmall}>
            Outputs
          </Typography>
          <div className={margin.top.extraSmall}>
            <IOPortTable
              ports={outputs}
              onChange={(updatedPorts) =>
                onUpdate({
                  ...item,
                  ports: [...inputs, ...updatedPorts],
                })
              }
            />
          </div>
          <div className={cx(margin.top.small, styles.buttonContainer)}>
            <AddPortButton
              label="Add output port"
              onAdd={(addedPort) =>
                onUpdate({
                  ...item,
                  ports: [...inputs, ...outputs, addedPort],
                })
              }
            />
          </div>
        </div>
      </CustomIOEquipmentManagerContextProvider>
    </div>
  );
}
