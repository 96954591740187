import type { ModbusTCPServerRegisterKind } from './types/types';

export function convertRegisterTypeToDisplayName(
  dataFormat: ModbusTCPServerRegisterKind,
) {
  const registerKindDisplayName = {
    inputRegister: 'Input Register',
    holdingRegister: 'Holding Register',
    inputCoil: 'Input Coil',
    statusCoil: 'Status Coil',
  };

  return registerKindDisplayName[dataFormat];
}
